<template>
	<article class="project" ref="project">
		<div class="project__menu">
			<filter-buttons
				class="projects__filters"
				translateKey="projects"
				:project="section"
				:projects="projects"
				@set-project="viewProject"
			/>
		</div>
		<project-filter
			v-if="itemTypes.length"
			:item-type="itemType"
			:item-types="itemTypes"
			@change="changeFilter"
		/>
		<div class="project__slider" :style="{ height }">
			<slider
				animation="no"
				:autoplay="false"
				:touch="false"
				:speed="300"
				v-model="sliderIndex"
				@next="changeIndex"
				@previous="changeIndex"
			>
				<slider-item v-for="item in project.assets" :key="item.name">
					<div class="slider__item">
						<div
							class="slider__image"
							:style="{
								'background-image': `url(${$imgUrl(
									`projects/${section.type}/${slugName}/${project.assets[sliderIndex].name}`
								)})`
							}"
							:key="project.assets[sliderIndex].name"
							@click="tinyIndex = sliderIndex"
						/>
					</div>
				</slider-item>
				<div slot="loading">loading...</div>
			</slider>
		</div>
		<div class="project__navigation">
			<div class="project__navigation-item">
				<button class="arrow-left" @click="prevProject" />
				<span><strong>{{ project.navigation[0] }}</strong></span> |
				<span>{{ project.navigation[1] }}</span> |
				<span>{{ project.navigation[2] }}</span>
				<button class="arrow-right" @click="nextProject" />
			</div>
		</div>
		<transition name="fade" mode="out-in">
			<div v-if="textVisible" class="project__info">
				<div class="project__info-item">
					<img :src="$imgUrl('PROJEKTY-architektura-TXT-icon.svg')" alt="icon" />
				</div>
				<div class="project__info-item">
					<div
						v-for="label in project.labels"
						:key="`${project.name}${label}`"
						class="project__info-label"
					>
						<template v-if="isObject(label)">
							<strong>{{ $t(`projects.${label.key}`) }}</strong>
							<span v-for="i in label.values" :key="`${label.key}.${i}`">
								{{ $t(`projects.${project.name}.${label.key}.${i}`) }}
							</span>
						</template>
						<template v-else>
							<strong>{{ $t(`projects.${label}`) }}</strong>
							<span>{{ $t(`projects.${project.name}.${label}`) }}</span>
						</template>
					</div>
				</div>
				<div class="project__info-item">
					<p
						v-for="text in project.texts"
						:key="`${project.name}.${text}`"
						class="project__info-paragraph"
					>
						{{ $t(`projects.${project.name}.texts.${text}`) }}
					</p>
				</div>
			</div>
		</transition>
		<div class="project__switch" @click="textVisible = !textVisible">
			<transition name="fade" mode="out-in">
				<img :src="$imgUrl(switchImage)" alt="switch" :key="textVisible" />
			</transition>
		</div>
		<Tinybox v-model="tinyIndex" :images="tinyImages" no-thumbs />
	</article>
</template>

<script>
import { forEach, find, includes, reduce, map, isObject } from 'lodash'
import { Slider, SliderItem } from 'vue-easy-slider'
import ProjectFilter from '@/components/project-filter'
import FilterButtons from '@/components/filter-buttons'
import Tinybox from 'vue-tinybox'

export default {
	components: {
		Slider,
		SliderItem,
		ProjectFilter,
		FilterButtons,
		Tinybox
	},
	data: () => ({
		section: null,
		project: null,
		sliderIndex: 0,
		firstEntry: true,
		height: '400px',
		tinyIndex: null,
		textVisible: false
	}),
	computed: {
		slug: ({ $route }) => $route.params.project,
		slugName: ({ $route }) => $route.params.name,
		projects: ({ $store }) => $store.getters['projects/data'],
		itemType: ({ project, sliderIndex }) => project.assets[sliderIndex].type,
		itemTypes: ({ project }) =>
			reduce(
				project.assets,
				(acc, curr) => {
					if (!includes(acc, curr.type)) {
						acc.push(curr.type)
					}
					return acc
				},
				[]
			),
		tinyImages: ({ section, project, $imgUrl }) =>
			map(project.assets, (item) => ({
				src: $imgUrl(`projects/${section.type}/${project.name}/big/${item.name}`)
			})),
		switchImage: ({ textVisible }) => (textVisible ? 'TXT-close.png' : 'TXT.svg')
	},
	created() {
		this.findProject()
	},
	mounted() {
		window.addEventListener('resize', this.calcSliderHeight)
		// make sure arrows are in the right place
		const hackTimer = setInterval(() => {
			if (
				document.querySelector('.slider-indicators') &&
				document.querySelector('.slider-indicators').style.width !== '0px'
			) {
				clearInterval(hackTimer)
				this.calcSliderHeight()
			}
		}, 100)
	},
	watch: {
		$route() {
			this.section = null
			this.project = null
			this.findProject()

			setTimeout(() => {
				this.calcSliderHeight()
			}, 1000)
		}
	},
	methods: {
		isObject,
		calcSliderHeight() {
			const indicatorsWidth = document.querySelector('.slider-indicators').getBoundingClientRect().width
			const arrowLeft = document.querySelector('.slider-btn-left')
			const arrowRight = document.querySelector('.slider-btn-right')
			const sliderWidth = document.querySelector('.project__slider').getBoundingClientRect().width

			if (window.innerWidth > 900) {
				arrowLeft.style.left = (((sliderWidth - indicatorsWidth) / 2) - arrowLeft.getBoundingClientRect().width - 10) + 'px'

				arrowRight.style.right = (((sliderWidth - indicatorsWidth) / 2) - arrowRight.getBoundingClientRect().width - 10) + 'px'

				this.height = this.$refs.project.getBoundingClientRect().height + 'px'
			}
		},
		findProject() {
			forEach(this.projects, (item) => {
				if (item.slug === this.slug) {
					this.section = item
				}
			})
			if (!this.section) {
				this.$router.push({ path: '/404' })
			} else {
				this.project = find(this.section.projects, { name: this.slugName })

				if (!this.project) {
					this.$router.push({ path: '/404' })
				}
			}
		},
		changeIndex({ next }) {
			this.sliderIndex = next
		},
		changeFilter(type) {
			const changedAsset = find(this.project.assets, { type })
			this.changeIndex({ next: changedAsset.id })
		},
		viewProject(item) {
			if (this.firstEntry) {
				this.firstEntry = false
			} else {
				this.$store.dispatch('settings/setProject', item)
				this.$router.push({ path: '/projekty' })
			}
		},
		prevProject() {
			const prev = find(this.section.projects, { id: +(this.project.id - 1) })

			if (prev) {
				this.$router.push({ path: `/projekty/${this.slug}/${prev.name}` })
			}
		},
		nextProject() {
			const next = find(this.section.projects, { id: +(this.project.id + 1) })

			if (next) {
				this.$router.push({ path: `/projekty/${this.slug}/${next.name}` })
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calcSliderHeight)
	}
}
</script>
