<template>
	<article class="projects">
		<div class="projects__projects">
			<transition name="fade" mode="out-in">
				<h2 class="projects__heading" :key="project.type">
					{{ $t(`projects.${project.type}.title`) }}
				</h2>
			</transition>
			<transition name="fade" mode="out-in">
				<div class="projects__projects-scroll" v-if="project" :key="project.type">
					<div class="projects__projects-wrap">
						<router-link
							tag="div"
							class="cover"
							v-for="item in project.projects"
							:key="item.name"
							:style="{
								'background-image': `url(${$imgUrl(
									`projects/${project.type}/${item.name}/${item.cover}`
								)})`
							}"
							:to="{ path: `/projekty/${project.slug}/${item.name}` }"
							@mouseover.native="imageHover = item"
							@mouseleave.native="imageHover = null"
						>
							<div
								class="cover__overlay"
								:style="{
									'background-image': `url(${$imgUrl(
										`projects/${project.type}/${item.name}/${item.overlay}`
									)})`
								}"
							/>
						</router-link>
					</div>
				</div>
			</transition>
		</div>
		<filter-buttons
			class="projects__filters"
			translateKey="projects"
			:project="project"
			:projects="projects"
			@set-project="setProject"
		/>
		<div class="projects__info">
			<transition name="fade" mode="out-in">
				<div v-if="imageHover" class="projects__info-cover">
					<div
						class="image"
						:style="{
							'background-image': `url(${$imgUrl(image)})`
						}"
					/>
				</div>
				<div v-else class="projects__info-cover" :key="id">
					<div
						class="image"
						:style="{
							'background-image': `url(${$imgUrl(
								`projects/${project.type}/${project.cover}`
							)})`
						}"
					/>
				</div>
			</transition>
		</div>
	</article>
</template>

<script>
import { get } from 'lodash'
import FilterButtons from '@/components/filter-buttons'

export default {
	components: { FilterButtons },
	data: () => ({
		project: null,
		imageHover: null,
		textVisible: false
	}),
	computed: {
		id: ({ project }) => get(project, 'id', null),
		projects: ({ $store }) => $store.getters['projects/data'],
		image: ({ project, imageHover }) =>
			`projects/${project.type}/${imageHover.name}/big/${imageHover.cover}`
	},
	created() {
		if (this.$store.getters['settings/project']) {
			this.setProject(this.$store.getters['settings/project'])
			this.$store.dispatch('settings/setProject', null)
		} else {
			this.setProject(this.projects[0])
		}
	},
	methods: {
		setProject(project) {
			this.project = project
			this.textVisible = false
		}
	}
}
</script>
