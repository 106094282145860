export const WORKSHOP = 'workshop'
export const LIBRARY = 'library'
export const DOCTORATE = 'doctorate'

export const ARCHITECTURE = 'architecture'
export const INTERIOR = 'interior'
export const FURNITURE = 'furniture'

export const ARCHITECTURE_SLUG = 'architektura'
export const INTERIOR_SLUG = 'wnetrza'
export const FURNITURE_SLUG = 'meble'

export const IDEA = 'idea'
export const PROJECTION = 'projection'
export const VISUALISATIONS = 'visualisations'
export const PHOTOS = 'photos'
