import {
	ARCHITECTURE,
	INTERIOR,
	FURNITURE,
	ARCHITECTURE_SLUG,
	INTERIOR_SLUG,
	FURNITURE_SLUG,
	IDEA,
	PROJECTION,
	VISUALISATIONS,
	PHOTOS
} from '@/constants'

export default {
	namespaced: true,
	state: {
		data: [
			{
				id: 0,
				type: ARCHITECTURE,
				slug: ARCHITECTURE_SLUG,
				cover:
					'IMG-nature-raw-architecture-project-design-mountain-tops-clouds-smoke-mood.jpg',
				projects: [
					{
						id: 0,
						name: 'muzeum-w-dobrzycy',
						cover: 'IKONA_szreniawa-bw.jpg',
						overlay: 'IKONA_szreniawa-multi.jpg',
						navigation: ['MUZEUM', 2016, 'Dobrzyca'],
						labels: ['project', 'investor', 'place', 'status', 'area'],
						texts: [0, 1, 2, 3],
						assets: [
							{
								id: 0,
								name: '01.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: '02.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: '03.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: '04.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: '05.jpg',
								type: VISUALISATIONS
							},
							{
								id: 5,
								name: '1B-final-update-01.jpg',
								type: PROJECTION
							},
							{
								id: 6,
								name: 'AKSONOMETRIE_MUZEUM_ztekstami-01.jpg',
								type: IDEA
							},
							{
								id: 7,
								name: 'AKSONOMETRIE_MUZEUM_ztekstami-02.jpg',
								type: IDEA
							},
							{
								id: 8,
								name: 'AKSONOMETRIE_MUZEUM_ztekstami-03.jpg',
								type: IDEA
							},
							{
								id: 9,
								name: 'AKSONOMETRIE_MUZEUM_ztekstami-04.jpg',
								type: IDEA
							}
						]
					},
					{
						id: 1,
						name: 'market',
						cover: 'ikona_1.jpg',
						overlay: 'ikona_1-multiply.jpg',
						navigation: ['Market', 2014, 'Borówiec'],
						labels: ['project', 'investor', 'place', 'status', 'area'],
						texts: [0, 1, 2, 3],
						assets: [
							{
								id: 0,
								name: 'sc_1_EXT_RGB_color_WIP-04.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'SC_2_EXT_RGB_color-FINAL.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'SC_3_INT_RGB_color-FINAL.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'SC_7_EXT_RGB_color_FINAL.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'SC_8_RGB_color-FINAL.jpg',
								type: VISUALISATIONS
							},
							{
								id: 5,
								name: 'SC_9_RGB_color-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 6,
								name: 'MARKET_rzut_FINAL-01.jpg',
								type: PROJECTION
							},
							{
								id: 7,
								name: 'MARKET_schematy-01.jpg',
								type: IDEA
							},
							{
								id: 8,
								name: 'MARKET_schematy-02.jpg',
								type: IDEA
							},
							{
								id: 9,
								name: 'MARKET_schematy-03.jpg',
								type: IDEA
							},
							{
								id: 10,
								name: 'MARKET_schematy-04.jpg',
								type: IDEA
							}
						]
					},
					{
						id: 2,
						name: 'akademik',
						cover: 'I_4_Akademik_ikona.jpg',
						overlay: 'I_4_Akademik_ikona_small_multi.jpg',
						navigation: ['Akademik', 2011, 'Mediolan'],
						labels: ['project', 'investor', 'place', 'status', 'area'],
						texts: [0, 1, 2, 3],
						assets: [
							{
								id: 0,
								name: 'I_4_Akademik_1.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'I_4_Akademik_2.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'I_4_Akademik_3.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'I_4_Akademik_4.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'I_4_Akademik_5.jpg',
								type: VISUALISATIONS
							},
							{
								id: 5,
								name: 'I_4_Akademik_6.jpg',
								type: VISUALISATIONS
							},
							{
								id: 6,
								name: 'AKADEMIK_rzut-01.jpg',
								type: PROJECTION
							},
							{
								id: 7,
								name: 'AKADEMIK_schematy-01.jpg',
								type: IDEA
							},
							{
								id: 8,
								name: 'AKADEMIK_schematy-02.jpg',
								type: IDEA
							},
							{
								id: 9,
								name: 'AKADEMIK_schematy-03.jpg',
								type: IDEA
							},
							{
								id: 10,
								name: 'AKADEMIK_schematy-04.jpg',
								type: IDEA
							}
						]
					},
					{
						id: 3,
						name: 'endom',
						cover: 'ENDOM_ikona.jpg',
						overlay: 'ENDOM_ikona-multiply.jpg',
						navigation: ['Endom', 2012, 'Poznań'],
						labels: ['project', 'investor', 'place', 'status', 'area'],
						texts: [0, 1, 2, 3],
						assets: [
							{
								id: 0,
								name: 'SC_1_EXT-small.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'SC_2_EXT-small.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'SC_3_INT-small.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'SC_4_EXT-small.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'SC_5_EXT_N-small.jpg',
								type: VISUALISATIONS
							},
							{
								id: 5,
								name: 'SC_6_EXT_N-small.jpg',
								type: VISUALISATIONS
							},
							{
								id: 6,
								name: 'ENDOM-rzut.jpg',
								type: PROJECTION
							},
							{
								id: 7,
								name: 'ENDOM-schemat-01.jpg',
								type: IDEA
							},
							{
								id: 8,
								name: 'ENDOM-schemat-02.jpg',
								type: IDEA
							},
							{
								id: 9,
								name: 'ENDOM-schemat-03.jpg',
								type: IDEA
							}
						]
					},
					{
						id: 4,
						name: 'koscielna',
						cover: 'kościelna_ikona.jpg',
						overlay: 'kościelna_ikona-multiply.jpg',
						navigation: ['Kościelna', 2021, 'Poznań'],
						labels: ['project', { key: 'authors', values: [0, 1] }, 'investor', 'place', 'status', 'area'],
						texts: [0, 1, 2, 3],
						assets: [
							{
								id: 0,
								name: 'wizualizacja_1.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'wizualizacja_2.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'wizualizacja_3.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'zdjęcia_1-final-01.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'zdjęcia_2-final-01.jpg',
								type: VISUALISATIONS
							},
							{
								id: 5,
								name: 'zdjęcia_3-final-01.jpg',
								type: VISUALISATIONS
							},
							{
								id: 6,
								name: 'Koscielna-rzut-final-02.jpg',
								type: PROJECTION
							}
						]
					},
					{
						id: 5,
						name: 'szczecinek',
						cover: 'SZCZECINEK-ikona.jpg',
						overlay: 'SZCZECINEK-multiply.jpg',
						navigation: ['Dom', 2021, 'Szczecinek'],
						labels: ['project', 'investor', 'place', 'status', 'area'],
						texts: [0, 1, 2],
						assets: [
							{
								id: 0,
								name: 'A-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'B-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'C-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'D-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'SZCZECINEK_rzuty-parter.jpg',
								type: PROJECTION
							},
							{
								id: 5,
								name: 'SZCZECINEK_rzuty-pietro.jpg',
								type: PROJECTION
							}
						]
					},
					{
						id: 6,
						name: 'domaszkow',
						cover: 'DOMASZKOW-ikona.jpg',
						overlay: 'DOMASZKOW-ikona-multiply.jpg',
						navigation: ['Dom', 2022, 'Domaszków'],
						labels: ['project', 'investor', 'place', 'status', 'area'],
						texts: [0, 1, 2],
						assets: [
							{
								id: 0,
								name: 'F1-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'F2-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'F3-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'F4-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'DOMASZKOW-rzut-parter.jpg',
								type: PROJECTION
							},
							{
								id: 5,
								name: 'DOMASZKOW-rzut-pietro.jpg',
								type: PROJECTION
							}
						]
					},
					{
						id: 7,
						name: 'ulanska',
						cover: 'ULANSKA-ikona.jpg',
						overlay: 'ULANSKA-ikona-multiply.jpg',
						navigation: ['Ułańska', 2022, 'Poznań'],
						labels: ['project', 'investor', 'place', 'status', 'area'],
						texts: [0, 1, 2],
						assets: [
							{
								id: 0,
								name: '01.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: '02.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: '03.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: '04.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'B_1.jpg',
								type: VISUALISATIONS
							},
							{
								id: 5,
								name: 'B_2.jpg',
								type: VISUALISATIONS
							},
							{
								id: 6,
								name: 'B_3.jpg',
								type: VISUALISATIONS
							},
							{
								id: 7,
								name: 'ULANSKA_rzut_2.jpg',
								type: PROJECTION
							}
						]
					}
				]
			},
			{
				id: 1,
				type: INTERIOR,
				slug: INTERIOR_SLUG,
				cover:
					'IMG-nature-raw-architecture-project-design-ear-grass-sun-flare-mood.jpg',
				projects: [
					{
						id: 0,
						name: 'river-park',
						cover: 'river_ikona.jpg',
						overlay: 'river_ikona.jpg',
						navigation: ['River Park', 2016, 'Poznań'],
						labels: ['project', 'investor', 'place', 'status', 'area'],
						texts: [0, 1, 2, 3],
						assets: [
							{
								id: 0,
								name: '1.jpg',
								type: PHOTOS
							},
							{
								id: 1,
								name: '2.jpg',
								type: PHOTOS
							},
							{
								id: 2,
								name: '3.jpg',
								type: PHOTOS
							},
							{
								id: 3,
								name: '4.jpg',
								type: PHOTOS
							},
							{
								id: 4,
								name: '5.jpg',
								type: PHOTOS
							},
							{
								id: 5,
								name: '6.jpg',
								type: PHOTOS
							},
							{
								id: 6,
								name: '7.jpg',
								type: PHOTOS
							},
							{
								id: 7,
								name: '8.jpg',
								type: PHOTOS
							},
							{
								id: 8,
								name: '9.jpg',
								type: PHOTOS
							},
							{
								id: 9,
								name: '10.jpg',
								type: PHOTOS
							},
							{
								id: 10,
								name: '11.jpg',
								type: PHOTOS
							},
							{
								id: 11,
								name: '12.jpg',
								type: PHOTOS
							},
							{
								id: 12,
								name: '13.jpg',
								type: PHOTOS
							},
							{
								id: 13,
								name: '14.jpg',
								type: PHOTOS
							},
							{
								id: 14,
								name: '15.jpg',
								type: PHOTOS
							},
							{
								id: 15,
								name: '16.jpg',
								type: PHOTOS
							},
							{
								id: 16,
								name: '17.jpg',
								type: PHOTOS
							},
							{
								id: 17,
								name: '18.jpg',
								type: PHOTOS
							},
							{
								id: 18,
								name: '19.jpg',
								type: PHOTOS
							},
							{
								id: 19,
								name: '20.jpg',
								type: PHOTOS
							}
						]
					},
					{
						id: 1,
						name: 'apartament-wieza',
						cover: 'wieza_ikona.jpg',
						overlay: 'wieza_ikona.jpg',
						navigation: ['Apartament Wieża', 2019, 'Wałcz'],
						labels: ['project', 'investor', 'place', 'status', 'area'],
						texts: [0, 1, 2, 3],
						assets: [
							{
								id: 0,
								name: '1.jpg',
								type: PHOTOS
							},
							{
								id: 1,
								name: '2.jpg',
								type: PHOTOS
							},
							{
								id: 2,
								name: '3.jpg',
								type: PHOTOS
							},
							{
								id: 3,
								name: '4.jpg',
								type: PHOTOS
							},
							{
								id: 4,
								name: '5.jpg',
								type: PHOTOS
							},
							{
								id: 5,
								name: '6.jpg',
								type: PHOTOS
							},
							{
								id: 6,
								name: '7.jpg',
								type: PHOTOS
							},
							{
								id: 7,
								name: '8.jpg',
								type: PHOTOS
							},
							{
								id: 8,
								name: '9.jpg',
								type: PHOTOS
							},
							{
								id: 9,
								name: '10.jpg',
								type: PHOTOS
							},
							{
								id: 10,
								name: '11.jpg',
								type: PHOTOS
							},
							{
								id: 11,
								name: '12.jpg',
								type: PHOTOS
							},
							{
								id: 12,
								name: '13.jpg',
								type: PHOTOS
							},
							{
								id: 13,
								name: '14.jpg',
								type: PHOTOS
							},
							{
								id: 14,
								name: '15.jpg',
								type: PHOTOS
							},
							{
								id: 15,
								name: '16.jpg',
								type: PHOTOS
							}
						]
					},
					{
						id: 2,
						name: 'szreniawa-przemysl',
						cover: 'SZRENIAWA_ikona.jpg',
						overlay: 'SZRENIAWA_ikona_multi-01.jpg',
						navigation: ['PRZETWÓRSTWO I PRZEMYSŁ', 2015, 'Szreniawa'],
						labels: ['project', 'investor', 'place', 'status', 'area'],
						texts: [0, 1, 2, 3],
						assets: [
							{
								id: 0,
								name: '1.jpg',
								type: PHOTOS
							},
							{
								id: 1,
								name: '2.jpg',
								type: PHOTOS
							},
							{
								id: 2,
								name: '3.jpg',
								type: PHOTOS
							},
							{
								id: 3,
								name: '4.jpg',
								type: PHOTOS
							},
							{
								id: 4,
								name: '5.jpg',
								type: PHOTOS
							},
							{
								id: 5,
								name: '6.jpg',
								type: PHOTOS
							},
							{
								id: 6,
								name: '7.jpg',
								type: PHOTOS
							},
							{
								id: 7,
								name: '8.jpg',
								type: PHOTOS
							},
							{
								id: 8,
								name: '9.jpg',
								type: PHOTOS
							},
							{
								id: 9,
								name: '10.jpg',
								type: PHOTOS
							},
							{
								id: 10,
								name: '11.jpg',
								type: PHOTOS
							},
							{
								id: 11,
								name: '12.jpg',
								type: PHOTOS
							},
							{
								id: 12,
								name: '13.jpg',
								type: PHOTOS
							},
							{
								id: 13,
								name: '14.jpg',
								type: PHOTOS
							},
							{
								id: 14,
								name: '15.jpg',
								type: PHOTOS
							},
							{
								id: 15,
								name: '16.jpg',
								type: PHOTOS
							},
							{
								id: 16,
								name: '17.jpg',
								type: PHOTOS
							},
							{
								id: 17,
								name: '18.jpg',
								type: PHOTOS
							},
							{
								id: 18,
								name: '19.jpg',
								type: PHOTOS
							},
							{
								id: 19,
								name: '20.jpg',
								type: PHOTOS
							},
							{
								id: 20,
								name: '21.jpg',
								type: PHOTOS
							},
							{
								id: 21,
								name: '22.jpg',
								type: PHOTOS
							},
							{
								id: 22,
								name: '23.jpg',
								type: PHOTOS
							},
							{
								id: 23,
								name: '24.jpg',
								type: PHOTOS
							},
							{
								id: 24,
								name: '25.jpg',
								type: PHOTOS
							},
							{
								id: 25,
								name: '26.jpg',
								type: PHOTOS
							},
							{
								id: 26,
								name: '27.jpg',
								type: PHOTOS
							},
							{
								id: 27,
								name: '28.jpg',
								type: PHOTOS
							}
						]
					},
					{
						id: 3,
						name: 'nawozenie-melioracja',
						cover: 'nawozenie_ikona.jpg',
						overlay: 'nawozenie_ikona.jpg',
						navigation: ['Nawożenie i Melioracja', 2014, 'Szreniawa'],
						labels: ['project', 'investor', 'place', 'status', 'area'],
						texts: [0, 1, 2, 3],
						assets: [
							{
								id: 0,
								name: '1.jpg',
								type: PHOTOS
							},
							{
								id: 1,
								name: '2.jpg',
								type: PHOTOS
							},
							{
								id: 2,
								name: '3.jpg',
								type: PHOTOS
							},
							{
								id: 3,
								name: '4.jpg',
								type: PHOTOS
							},
							{
								id: 4,
								name: '5.jpg',
								type: PHOTOS
							},
							{
								id: 5,
								name: '6.jpg',
								type: PHOTOS
							},
							{
								id: 6,
								name: '7.jpg',
								type: PHOTOS
							},
							{
								id: 7,
								name: '8.jpg',
								type: PHOTOS
							},
							{
								id: 8,
								name: '9.jpg',
								type: PHOTOS
							},
							{
								id: 9,
								name: '10.jpg',
								type: PHOTOS
							},
							{
								id: 10,
								name: '11.jpg',
								type: PHOTOS
							},
							{
								id: 11,
								name: '12.jpg',
								type: PHOTOS
							},
							{
								id: 12,
								name: '13.jpg',
								type: PHOTOS
							},
							{
								id: 13,
								name: '14.jpg',
								type: PHOTOS
							},
							{
								id: 14,
								name: '15.jpg',
								type: PHOTOS
							},
							{
								id: 15,
								name: '16.jpg',
								type: PHOTOS
							},
							{
								id: 16,
								name: '17.jpg',
								type: PHOTOS
							},
							{
								id: 17,
								name: '18.jpg',
								type: PHOTOS
							},
							{
								id: 18,
								name: '19.jpg',
								type: PHOTOS
							},
							{
								id: 19,
								name: '20.jpg',
								type: PHOTOS
							},
							{
								id: 20,
								name: '21.jpg',
								type: PHOTOS
							},
							{
								id: 21,
								name: '22.jpg',
								type: PHOTOS
							},
							{
								id: 22,
								name: '23.jpg',
								type: PHOTOS
							},
							{
								id: 23,
								name: '24.jpg',
								type: PHOTOS
							},
							{
								id: 24,
								name: '25.jpg',
								type: PHOTOS
							}
						]
					}
				]
			},
			{
				id: 2,
				type: FURNITURE,
				slug: FURNITURE_SLUG,
				cover:
					'IMG-nature-raw-architecture-project-design-forrest-fog-smoke-mood.jpg',
				projects: [
					{
						id: 0,
						name: 'stol',
						cover: 'IKONA_STOL_bw.jpg',
						overlay: 'IKONA_STOL_multi-01.jpg',
						navigation: ['RIVER PARK', 2016, 'Poznań'],
						labels: ['project', 'investor', 'place', 'status'],
						texts: [0, 1],
						assets: [
							{
								id: 0,
								name: 'S_SC_1.RGB_color_person.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'S_SC_2.RGB_color.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'S_SC_3.RGB_color.jpg',
								type: VISUALISATIONS
							}
						]
					},
					{
						id: 1,
						name: 'regal',
						cover: 'IKONA_REGAL_bw.jpg',
						overlay: 'IKONA_REGAL_multi-01.jpg',
						navigation: ['RIVER PARK', 2016, 'Poznań'],
						labels: ['project', 'investor', 'place', 'status'],
						texts: [0, 1],
						assets: [
							{
								id: 0,
								name: 'R_SC_1.RGB_color.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'R_SC_2.RGB_color.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'R_SC_4.RGB_color.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'R_SC_5.RGB_color.jpg',
								type: VISUALISATIONS
							}
						]
					},
					{
						id: 2,
						name: 'lozko',
						cover: 'IKONA_LOZKO_bw.jpg',
						overlay: 'IKONA_LOZKO_multi-01.jpg',
						navigation: ['RIVER PARK', 2016, 'Poznań'],
						labels: ['project', 'investor', 'place', 'status'],
						texts: [0, 1],
						assets: [
							{
								id: 0,
								name: 'L_SC_2.RGB_color.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'L_SC_4.RGB_color.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'L_SC_5.RGB_color.jpg',
								type: VISUALISATIONS
							}
						]
					}
				]
			}
		]
	},
	getters: {
		data: (state) => state.data
	},
	mutations: {},
	actions: {}
}
